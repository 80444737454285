/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages

        // google map
        function initMap(){
          var myLatLng = {lat: -41.4832936, lng: 173.95851770000002 };
          var map = new google.maps.Map(document.getElementById('map'), {
            center: myLatLng,
            zoom: 15,
            scrollwheel: false,
            disableDefaultUI: true,
            mapTypeId:google.maps.MapTypeId.ROADMAP
          });
          var marker = new google.maps.Marker({
            position: myLatLng,
            map: map,
          });

          var contentString = '<div id="content"> ' +
                              '<h5 id="firstHeading" class="firstHeading">Eliza&#39;s Garden Cottage </h5></hr >' +
                              '<p>Address: 50 Staces Road, Grovetown 7202, Blenheim</p>'+
                              '<p>Phone: <a href="tel:021323358">021 323 358</a></p>' +
                              '<p>Email: <a href="mailto:elizasgardencottage@gmail.com">elizasgardencottage@gmail.com</a></p>' +
                              '</div>';

          var infowindow = new google.maps.InfoWindow({
            content: contentString
          });
          marker.addListener('click', function(){
              infowindow.open(map, marker);
          });
          google.maps.event.addDomListener(window, 'resize', function(){
            var center = map.getCenter();
            google.maps.event.trigger(map,"resize");
            map.setCenter(center);
          });

        }

        $(window).load(function() {
          initMap();
        });
        

      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
        $('.banner-slider').slick({
          arrows: false,
          slide: '.banner-slider__slide',
          asNavFor: '.banner-slider-nav'
        });
        $('.banner-slider-nav').slick({
          arrows: false,
          slide: '.banner-slider-nav__slide',
          slidesToShow: 5,
          slidesToScroll: 1,
          asNavFor: '.banner-slider',
          focusOnSelect: true
        });
        $('.swipebox').swipebox({
          hideBarsDelay: 0
        });
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // Contact page
    'contact': {
      init: function() {
        // JavaScript to be fired on the contact page
        $('#map').matchHeight({
          target: $('.contact-page-form')
        });
      },
      finalize: function() {
        // JavaScript to be fired on the contact page, after the init JS
      }
    },
    // Single room page
    'single': {
      init: function() {
        // JavaScript to be fired on the single post room pages
        $('.room-gallery').slick( {
          dots: true
        });
      }
    },
    // gallery page
    'gallery': {
      init: function() {
        $('.swipebox').swipebox({
          hideBarsDelay: 0
        });
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
